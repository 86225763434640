<div nz-row [nzGutter]="[10, 10]">
  <ng-container *ngFor="let incident of incidents">
    <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="8" nzXl="6" nzXXl="6" class="desktop-xxl:max-w-[14%]">
      <nz-card [nzBordered]="false" (click)="emitCardClick(incident)" style="zoom:90%;" class="cursor-pointer">
        <div class="flex justify-between items-center overflow-hidden">
          <div class="flex items-center" *ngIf="incident?.display_name">
            <span class="mr-1" style="font-size: 16px !important;" nz-icon
              [nzType]="'hezky:Location'"></span>
            <span class="text-sm font-normal">{{ incident.display_name }}</span>
          </div>
        </div>
        <div class="text-lg font-semibold text-gray-600">{{ incident.asset_name }}</div>
        <div class="flex flex-nowrap grid-flow-col gap-2 my-[0.8] overflow-x-auto overflow-y-hidden no-scrollbar">
          <ng-container [ngSwitch]="incident.incident_type">
            <ng-container *ngSwitchCase="'Temperature'">
              <div style="font-size: 36px !important; margin-left: -5px; margin-right: -10px" nz-icon
                [nzType]="'hezky:'+incident.incident_type+'_'+incident.severity">
              </div>
              <div class="text-[2.6rem] font-normal" [ngClass]="{'text-danger': incident.severity === 'High',
              'text-[#FF8D0F]': incident.severity === 'Medium','text-warning': incident.severity === 'Low'}"> {{
                commonService.getFormattedTemp(incident.current_alarm, incident.incident_type.toLowerCase())}}</div>
            </ng-container>
            <ng-container *ngSwitchCase="'Humidity'">
              <div style="font-size: 34px !important" nz-icon
                [nzType]="'hezky:'+incident.incident_type+'_'+incident.severity">
              </div>
              <div class="text-[2.6rem]" [ngClass]="{'text-danger': incident.severity === 'High',
              'text-[#FF8D0F]': incident.severity === 'Medium','text-warning': incident.severity === 'Low'}">{{
                commonService.getFormattedTemp(incident.current_alarm, incident.incident_type.toLowerCase()) }}</div>
            </ng-container>
            <ng-container *ngSwitchCase="'Pressure'">
              <div style="font-size: 30px !important" nz-icon
                [nzType]="'hezky:'+incident.incident_type+'_'+incident.severity">
              </div>
              <div class="text-[2.6rem] " [ngClass]="{'text-danger': incident.severity === 'High',
              'text-[#FF8D0F]': incident.severity === 'Medium','text-warning': incident.severity === 'Low'}">{{
                commonService.getFormattedTemp(incident.current_alarm, incident.incident_type.toLowerCase()) }}</div>
            </ng-container>
          </ng-container>
          <div class="mt-3" [ngClass]="{'text-danger': incident.severity === 'High',
          'text-[#FF8D0F]': incident.severity === 'Medium','text-warning': incident.severity === 'Low'}">
            <span class="text-right text-[0.9rem] text-nowrap">{{incident.incident_name}}</span> <br>
            <span class="mt-2 text-[0.7rem] text-nowrap">
              <ng-container [ngSwitch]="incident.threshold_condition">
                <ng-container *ngSwitchCase="'Less than'">
                  Below {{ commonService.getFormattedTemp(incident.threshold_value, incident.incident_type.toLowerCase()) }}
                </ng-container>
                <ng-container *ngSwitchCase="'Greater than'">
                  Above {{ commonService.getFormattedTemp(incident.threshold_value, incident.incident_type.toLowerCase()) }}
                </ng-container>
              </ng-container>
            <span class="">{{ incident.triggered_time | relativeTime:"card" }}</span>
            </span>
          </div>
        </div>
        <div class="text-gray-600 text-xs font-semibold mt-2">
          <i nz-icon nzType="calendar" nzTheme="outline" class="mr-1"></i>
          {{ incident.triggered_time | date: "MMM d, y" }}
          <i nz-icon nzType="clock-circle" nzTheme="outline" class="ml-2"></i>
          {{ incident.triggered_time | date: "HH:mm:ss" }}
        </div>
      </nz-card>
    </div>
  </ng-container>
</div>