export const countryCodes = [
      { "country": "Afghanistan", "code": "+93" },
      { "country": "Albania", "code": "+355" },
      { "country": "Argentina", "code": "+54" },
      { "country": "Armenia", "code": "+374" },
      { "country": "Australia", "code": "+61" },
      { "country": "Austria", "code": "+43" },
      { "country": "Azerbaijan", "code": "+994" },
      { "country": "Bahrain", "code": "+973" },
      { "country": "Bangladesh", "code": "+880" },
      { "country": "Belarus", "code": "+375" },
      { "country": "Belgium", "code": "+32" },
      { "country": "Bosnia and Herzegovina", "code": "+387" },
      { "country": "Brazil", "code": "+55" },
      { "country": "Bulgaria", "code": "+359" },
      { "country": "Canada", "code": "+1" },
      { "country": "Chile", "code": "+56" },
      { "country": "China", "code": "+86" },
      { "country": "Colombia", "code": "+57" },
      { "country": "Croatia", "code": "+385" },
      { "country": "Czech Republic", "code": "+420" },
      { "country": "Denmark", "code": "+45" },
      { "country": "Egypt", "code": "+20" },
      { "country": "Estonia", "code": "+372" },
      { "country": "Finland", "code": "+358" },
      { "country": "France", "code": "+33" },
      { "country": "Georgia", "code": "+995" },
      { "country": "Germany", "code": "+49" },
      { "country": "Ghana", "code": "+233" },
      { "country": "Greece", "code": "+30" },
      { "country": "Hungary", "code": "+36" },
      { "country": "Iceland", "code": "+354" },
      { "country": "India", "code": "+91" },
      { "country": "Indonesia", "code": "+62" },
      { "country": "Iraq", "code": "+964" },
      { "country": "Ireland", "code": "+353" },
      { "country": "Italy", "code": "+39" },
      { "country": "Japan", "code": "+81" },
      { "country": "Kazakhstan", "code": "+7" },
      { "country": "Kenya", "code": "+254" },
      { "country": "Kuwait", "code": "+965" },
      { "country": "Latvia", "code": "+371" },
      { "country": "Lebanon", "code": "+961" },
      { "country": "Liechtenstein", "code": "+423" },
      { "country": "Lithuania", "code": "+370" },
      { "country": "Luxembourg", "code": "+352" },
      { "country": "Malaysia", "code": "+60" },
      { "country": "Mexico", "code": "+52" },
      { "country": "Mongolia", "code": "+976" },
      { "country": "Montenegro", "code": "+382" },
      { "country": "Morocco", "code": "+212" },
      { "country": "Nepal", "code": "+977" },
      { "country": "Netherlands", "code": "+31" },
      { "country": "New Zealand", "code": "+64" },
      { "country": "Nigeria", "code": "+234" },
      { "country": "North Macedonia", "code": "+389" },
      { "country": "Norway", "code": "+47" },
      { "country": "Oman", "code": "+968" },
      { "country": "Pakistan", "code": "+92" },
      { "country": "Palestine", "code": "+970" },
      { "country": "Philippines", "code": "+63" },
      { "country": "Poland", "code": "+48" },
      { "country": "Portugal", "code": "+351" },
      { "country": "Qatar", "code": "+974" },
      { "country": "Romania", "code": "+40" },
      { "country": "Russia", "code": "+7" },
      { "country": "Saudi Arabia", "code": "+966" },
      { "country": "Serbia", "code": "+381" },
      { "country": "Singapore", "code": "+65" },
      { "country": "Slovakia", "code": "+421" },
      { "country": "Slovenia", "code": "+386" },
      { "country": "South Africa", "code": "+27" },
      { "country": "South Korea", "code": "+82" },
      { "country": "Spain", "code": "+34" },
      { "country": "Sri Lanka", "code": "+94" },
      { "country": "Sweden", "code": "+46" },
      { "country": "Switzerland", "code": "+41" },
      { "country": "Syria", "code": "+963" },
      { "country": "Taiwan", "code": "+886" },
      { "country": "Tajikistan", "code": "+992" },
      { "country": "Thailand", "code": "+66" },
      { "country": "Turkey", "code": "+90" },
      { "country": "Turkmenistan", "code": "+993" },
      { "country": "Ukraine", "code": "+380" },
      { "country": "United Arab Emirates", "code": "+971" },
      { "country": "United Kingdom", "code": "+44" },
      { "country": "United States", "code": "+1" },
      { "country": "Uzbekistan", "code": "+998" },
      { "country": "Venezuela", "code": "+58" },
      { "country": "Vietnam", "code": "+84" },
      { "country": "Yemen", "code": "+967" },
      { "country": "Zambia", "code": "+260" },
      { "country": "Zimbabwe", "code": "+263" }
    ]
  