import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { AssetsState } from '../../../modules/assets/state/assets.state';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-asset-cards',
  templateUrl: './asset-cards.component.html',
  styleUrl: './asset-cards.component.scss'
})
export class AssetCardsComponent implements OnChanges {
  @Input('data') cardData: any;
  constructor(protected commonSvc:CommonService,protected store: Store<AssetsState>) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['cardData']) {
      this.cardData = changes['cardData'].currentValue;
    }
  }
}
