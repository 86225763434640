import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { UiService } from '../../services/ui.service';
interface NotificationItem {
  notification_id: number;
  notification_type: string;
  notification: string;
  created_at: any;
  ismarked: boolean;
}
interface GroupedNotifications {
  date: string;
  items: NotificationItem[];
}
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.scss'
})
export class NotificationComponent {
  @Input() notifications: NotificationItem[] = [];
  @Input() unreadCount: number = 0;
  @Output() markedClick = new EventEmitter();
  @Output() loadMore = new EventEmitter<number>();
  @Output() routeToNavigation = new EventEmitter();
  @Output() refreshClick = new EventEmitter();
  noNewNotification = false;
  initLoading = false;
  currentFilter: 'all' | 'unread' = 'all';
  private allGroupedNotifications: GroupedNotifications[] = [];
  displayedNotifications: GroupedNotifications[] = [];
  pageSize = 10;
  currentPage = 1;
  constructor(
    protected commonSvc: CommonService,
    public uiSvc: UiService
  ) { }
  ngOnInit() {
    this.processNotifications();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['notifications']?.currentValue && !changes['notifications'].firstChange) {
      this.notifications = changes['notifications'].currentValue;
      this.changeFilter('all');
    }
  }
  refreshHandler = () => this.refreshClick.emit();
  processNotifications() {
    const filteredNotifications = this.filterNotifications();
    this.allGroupedNotifications = this.groupNotificationsByDate(filteredNotifications);
    this.displayedNotifications = this.getPagedNotifications();
  }
  private getPagedNotifications(): GroupedNotifications[] {
    let count = 0;
    const result: GroupedNotifications[] = [];
    for (const group of this.allGroupedNotifications) {
      const remainingSlots = (this.currentPage * this.pageSize) - count;
      if (count >= this.currentPage * this.pageSize) break;

      if (group.items.length <= remainingSlots) {
        result.push(group);
        count += group.items.length;
      } else {
        result.push({
          date: group.date,
          items: group.items.slice(0, remainingSlots)
        });
        count += remainingSlots;
      }
    }
    return result;
  }
  filterNotifications(): NotificationItem[] {
    return this.currentFilter === 'unread'
      ? this.notifications?.filter(note => !note.ismarked)
      : this.notifications.length ? [ ...this.notifications] : [];
  }
  groupNotificationsByDate(notifications: NotificationItem[]): GroupedNotifications[] {
    const groups = new Map<string, NotificationItem[]>();
    notifications
      ?.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
      ?.forEach(notification => {
        const date = this.getDateGroup(notification.created_at);
        if (!groups.has(date)) groups.set(date, []);
        groups.get(date)?.push(notification);
      });
    return Array.from(groups.entries())
      .sort((a, b) => {
        if (a[0] === 'Today') return -1;
        if (b[0] === 'Today') return 1;
        if (a[0] === 'Yesterday') return -1;
        if (b[0] === 'Yesterday') return 1;
        return new Date(b[0]).getTime() - new Date(a[0]).getTime();
      })
      .map(([date, items]) => ({ date, items }));
  }
  onScroll(event: any) {
    const target = event.target;
    const scrollPosition = target.scrollTop + target.clientHeight;
    const scrollHeight = target.scrollHeight;
    if (scrollPosition >= scrollHeight - 10) {
      const totalDisplayed = this.getTotalDisplayedItems();
      if (!this.initLoading && totalDisplayed % 10 === 0 && totalDisplayed < this.getTotalItems()) {
        this.initLoading = true;
        setTimeout(() => {
          this.currentPage++;
          this.displayedNotifications = this.getPagedNotifications();
          this.loadMore.emit(this.currentPage);
          this.initLoading = false;
        }, 500);
      }
    }
  }

  private getTotalDisplayedItems = () => this.displayedNotifications.reduce((sum, group) => sum + group.items.length, 0);
  private getTotalItems = () => this.allGroupedNotifications.reduce((sum, group) => sum + group.items.length, 0);
  markAsRead = (items: any) => this.markedClick.emit(items);
  routeToComponent = (items: any) => {
    this.markAsRead(items); // Trigger markAsRead
    this.routeToNavigation.emit(items); // Proceed with routing
  };
  changeFilter(filter: 'all' | 'unread') {
    this.currentFilter = filter;
    this.currentPage = 1;
    this.processNotifications();
  }
  private getDateGroup(date: string): string {
    const diffDays = Math.floor(
      (new Date().getTime() - new Date(date).getTime()) / (1000 * 60 * 60 * 24)
    );
    if (diffDays === 0) return 'Today';
    if (diffDays === 1) return 'Yesterday';
    return new Date(date).toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric'
    });
  }

}
