<nz-badge [nzCount]="cardData.alarm_count" class="w-full" [nzOffset]="[-8,10]">
    <a [routerLink]="!commonSvc.roleService.hasRole('Security')?['/assets/',cardData?.asset_id]:null" role="button">
        <nz-card [nzBordered]="false" [ngClass]="cardData.alarm_count > 0 ? 'text-danger' : 'text-[#009A2F]'" class="min-h-[180px]" style="zoom:90%">
            <div class="flex items-center justify-between text-gray-600">
                <div>
                    <i nz-icon nzType="hezky:Location" style="font-size: 16px !important;" nzTheme="outline" class="mr-2"></i>
                    <span class="text-sm font-normal">{{ cardData?.display_name}}</span>
                </div>
                <span [nzTooltipColor]="'#fff'" nz-tooltip [nzTooltipTitle]="cardData?.battery + '%'" style="font-size: 1.4rem;" class="cursor-pointer" *ngIf="cardData?.battery > 0 && cardData?.battery <= 20" nz-icon [nzType]="'hezky:Battery_Low'" nzTheme="outline"></span>
            </div>
            <div class="text-lg font-semibold text-gray-600 cursor-pointer" style="margin-bottom: 6px;" nz-typography nzEllipsis>{{ cardData?.asset_name }}</div>
            <div class="flex items-center justify-start gap-2 text-xs w-full">
                <div class="flex items-center justify-between w-full">
                    <p *ngIf="cardData?.capabilities && cardData?.capabilities?.includes('Temperature')">
                        24H &nbsp;
                        {{ commonSvc.getFormattedTemp(cardData?.max_temp,'temperature') }} |
                        {{ commonSvc.getFormattedTemp(cardData?.min_temp,'temperature') }}
                    </p>
                    <p *ngIf="cardData?.capabilities && cardData?.capabilities?.includes('Pressure')">
                        24H &nbsp;
                        {{ commonSvc.getFormattedTemp(cardData?.max_pressure,'pressure') }} |
                        {{ commonSvc.getFormattedTemp(cardData?.min_pressure,'pressure') }}
                    </p>
                </div>
            </div>
            <div class="flex items-center justify-start mx-0 flex-nowrap scroll-smooth overflow-x-auto no-scrollbar overflow-y-hidden gap-2">
                <ng-container *ngIf="cardData?.sensors && cardData?.sensors?.length > 0">
                    <ng-container *ngFor="let sensor of cardData?.sensors; trackBy: commonSvc.trackByFn; let even = even">
                        <div *ngIf="sensor?.capabilities && sensor?.capabilities?.includes('Temperature')" class="flex flex-nowrap items-center gap-x-1">
                            <div class="flex flex-nowrap" *ngIf="sensor.capabilities.includes('Temperature')">
                                <i nz-icon style="font-size: 28px !important; margin-top: 0.9px;" [nzType]="cardData.alarm_count > 0 ? 'hezky:Temperature_High' : 'hezky:Temperature_Normal'"
                                    nzTheme="outline"></i>
                                <span class="flex flex-col items-center justify-center gap-0" *ngIf="sensor?.has_pressure">
                                    <small class="text-xsm font-semibold" style="margin: 0 0 -3px 0 ;">
                                        {{ sensor?.sensor_type === 'hot spot' ? 'H' : 'C'}}
                                    </small>
                                    <small class="text-xsm font-semibold">S</small>
                                </span>
                            </div>
                            <span style="font-size: 31px;" class="font-normal text-nowrap"> {{ sensor.sensor_type ===
                                'hot spot' ?
                                commonSvc.getFormattedTemp(sensor?.temperature,'temperature') : sensor.sensor_type ===
                                'cold spot' ?
                                commonSvc.getFormattedTemp(sensor?.temperature,'temperature') : sensor?.temperature
                                }}
                            </span>
                            <span class="text-4xl font-light mb-1" *ngIf="even && cardData?.sensors?.length > 1"> &nbsp;|</span>
                        </div>
                        <div *ngIf="sensor?.capabilities && sensor?.capabilities?.includes('Pressure')" class="flex flex-nowrap items-center gap-x-2">
                            <div class="flex flex-nowrap" *ngIf="sensor.capabilities.includes('Pressure')">
                                <i nz-icon *ngIf="cardData.alarm_count > 0 " style="font-size: 29px !important;" nzType="hezky:Pressure_High" nzTheme="outline"></i>
                                <i nz-icon *ngIf="cardData.alarm_count === 0" style="font-size: 29px !important;" nzType="hezky:Pressure" nzTheme="outline"></i>
                            </div>
                            <span style="font-size: 24px;" class="font-semibold text-nowrap"> {{ commonSvc.getFormattedTemp(sensor?.pressure,'pressure') }}</span>
                            <span class="text-4xl font-light" *ngIf="even && cardData?.sensors?.length > 1"> &nbsp; |</span>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="!cardData?.sensors || cardData?.sensors.length === 0">
                    <div class="h-full w-full">
                        <p class="flex place-content-center font-semibold h-40px">No sensors data found!</p>
                    </div>
                </ng-container>
            </div>
            <div *ngIf="cardData?.reading_on" class="text-gray-600 flex gap-x-2 text-xs font-semibold mt-3">
                <div>
                    <i nz-icon nzType="calendar" nzTheme="outline"></i>
                    {{ cardData?.reading_on | date: "MMM d, y" }}
                </div>
                <div>
                    <i nz-icon nzType="clock-circle" nzTheme="outline"></i>
                    {{ cardData?.reading_on | date: "HH:mm:ss" }}
                </div>
            </div>
        </nz-card>
    </a>
</nz-badge>