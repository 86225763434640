<!-- * HEADER -->
<nz-page-header class="px-0 py-0" nzBackIcon>
    <nz-page-header-title class="font-semibold text-base">{{config.formName | titlecase}}</nz-page-header-title>
</nz-page-header>
<nz-divider class="my-4"></nz-divider>
<form nz-form [nzLayout]="'vertical'" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <div nz-row nzGutter="16">
        <div nz-col class="gutter-row mb-4" nzXs="24" nzMd="8" nzLg="8" nzXl="6" *ngFor="let field of config.fields">
            <nz-form-item class="m-0">
                <nz-form-label [nzRequired]="hasValidatorType(field, 'required')" [nzForControl]="field.name">{{
                    field.label }}</nz-form-label>
                <nz-form-control [ngSwitch]="field.type" [nzErrorTip]="validationErrorTemplate">

                    <!-- Text Input -->
                    <input *ngSwitchCase="'text'" nz-input [formControlName]="field.name" [id]="field.name"
                        [type]="'text'" [placeholder]="field.placeholder" [class]="field.customAttributes?.class"
                        [attr]="getCustomAttributes(field.customAttributes)"
                        class="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        (ngModelChange)="field.onChangeMethod?.($event)" />

                    <!-- Email Input -->
                    <input *ngSwitchCase="'email'" nz-input [formControlName]="field.name" [id]="field.name"
                        [type]="'email'" [placeholder]="field.placeholder" [class]="field.customAttributes?.class"
                        [attr]="getCustomAttributes(field.customAttributes)"
                        class="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        (ngModelChange)="field.onChangeMethod?.($event)" />

                    <!-- Text Area -->
                    <textarea *ngSwitchCase="'textarea'" [formControlName]="field.name" [id]="field.name"
                        [placeholder]="field.placeholder" [attr]="getCustomAttributes(field.customAttributes)"
                        class="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        (ngModelChange)="field.onChangeMethod?.($event)"></textarea>

                    <!-- Single Select -->
                    <nz-select *ngSwitchCase="'select'" class="mt-1 w-full" [formControlName]="field.name"
                        [id]="field.name" [placeHolder]="field.placeholder" [nzShowSearch]="true">
                        <nz-option *ngFor="let option of field.options" [nzValue]="option.nzValue"
                            [nzLabel]="option.nzLabel">
                        </nz-option>
                    </nz-select>

                    <!-- Multi Select -->
                    <ng-template #customRemoveIcon>
                        <!-- <i nz-icon nzType="delete"></i> -->
                    </ng-template>
                    <nz-select *ngSwitchCase="'multiselect'" class="mt-1 w-full"
                        [nzRemoveIcon]="field.name === 'capabilities' ? customRemoveIcon : null"
                        [formControlName]="field.name" [nzMaxTagCount]="2" [id]="field.name" [nzShowSearch]="true"
                        [placeHolder]="field.placeholder" nzMode="multiple">
                        <nz-option *ngFor="let option of field['options']" [nzValue]="option.nzValue"
                            [nzLabel]="option.nzLabel"></nz-option>
                    </nz-select>

                    <!-- Checkbox -->
                    <div *ngSwitchCase="'checkbox'" class="flex flex-col gap-2">
                        <nz-form-label class="inline-flex items-center" [ngClass]="{'no-padding' : isGatewayForm}">
                            <input nz-input type="checkbox" [formControlName]="field.name" [id]="field.name"
                                [attr]="getCustomAttributes(field.customAttributes)" class="form-checkbox h-5 w-5 mr-2"
                                (ngModelChange)="field.onChangeMethod?.($event)" />
                            {{field.subLabel}}
                        </nz-form-label>
                        <!-- Extra input field for Gateway forms -->
                        <div *ngIf="isGatewayForm && field.type === 'checkbox'">
                            <input nz-input formControlName="severity" placeholder="Enter severity"
                                class="mt-1 block w-full border-gray-300 rounded-md shadow-sm sm:text-sm" />
                        </div>
                    </div>
                    <!-- Conatct -->
                    <nz-input-group class="rounded-lg" nzCompact *ngSwitchCase="'contact'">
                        <nz-select nz-input nzShowSearch name="countryCode" id="countryCode"
                            formControlName="countryCode" style="width:40%;">
                            <nz-option [nzValue]="country.code" [nzLabel]="'(' + country.code + ') ' + country.country"
                                *ngFor="let country of countryCodes$"></nz-option>
                        </nz-select>
                        <input [maxlength]="field.validators?.[3]?.value || null" [minlength]="field.validators?.[2]?.value || null" nz-input OnlyNumber [formControlName]="field.name" [id]="field.name" type="text" style="width:60%;" />
                    </nz-input-group>
                </nz-form-control>
                <!-- Validation Messages -->
                <ng-template #validationErrorTemplate let-control>
                    <ng-container *ngFor="let validator of field.validators">
                        <span *ngIf="control.touched && control.invalid && control.errors?.[validator.type]">{{
                            validator.message }}</span>
                    </ng-container>
                </ng-template>
            </nz-form-item>
        </div>
    </div>
    <nz-divider class="my-4"></nz-divider>
    <!--  Buttons Section-->
    <div class="flex flex-row flex-nowrap justify-end">
        <button nz-button type="button" *ngIf="config.formType === 'create'"
            class="bg-gray-300 text-black py-1 px-6 rounded-md shadow-sm mr-2 hover:bg-secondary-hover" nz-popconfirm
            nzPopconfirmPlacement="bottom" (nzOnConfirm)="resetform()" [disabled]="!formGroup.touched || formGroup.pristine"
            nzPopconfirmTitle="Are you sure you want to reset this form?">
            Reset
        </button>
        <button *ngIf="config.formType === 'create'" nz-button type="button"
            class="bg-primary text-white py-1 px-6 rounded-md shadow-sm mr-2 hover:bg-secondary-hover"
            [disabled]="formGroup.invalid" (click)="onAddAnother()">
            Save + Add another
        </button>
        <button *ngIf="isEditing" nz-button type="button" class="bg-primary text-white py-1 px-6"
            [disabled]="!isEditing || formGroup.invalid" (click)="onUpdateRecord()">
            Update Record
        </button>
        <button *ngIf="!isMultirecord" nz-button type="button"
            class="bg-primary text-white py-1 px-6 rounded-md shadow-sm hover:bg-secondary-hover"
             nz-popconfirm
            [nzPopconfirmTitle]="'Are you sure you want to ' + (config.formType === 'create' ? 'submit' : 'update') + ' this record?'"
            nzPopconfirmPlacement="bottom" (nzOnConfirm)="onSubmit()">
            {{ config.formType === 'create' ? 'Submit' : 'Update' }}
        </button>
    </div>
    <nz-divider *ngIf="isMultirecord" class="my-4"></nz-divider>
    <div *ngIf="isMultirecord">
        <nz-table #nzTable nz-table [nzBordered]="true" [nzData]="multiRecordList" [nzSize]="'small'"
            [nzBordered]="false">
            <thead>
                <tr>
                    <th *ngFor="let field of config.fields">{{ field.label }}</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let record of multiRecordList ; let i = index">
                    <ng-container *ngFor="let field of config.fields">
                        <td *ngIf="field.label !== 'Capabilities'">{{ record[field.name] }}</td>
                        <td *ngIf="field.label === 'Capabilities'">
                            <ng-container *ngFor="let capability of record[field.name]; index as i">
                                <span class="mr-1" style="font-size: 18px !important;" [nzTooltipColor]="'#fff'" [nz-tooltip]="capability" nz-icon
                                    [nzType]="'hezky:'+capability"></span>
                            </ng-container>
                        </td>
                    </ng-container>
                    <td>
                        <span [nzTooltipColor]="'#fff'" nz-tooltip="Edit" nz-icon nzType="edit" nzTheme="outline" (click)="onEdit(record, i)"
                            class="text-base text-primary cursor-pointer"></span>
                        <span [nzTooltipColor]="'#fff'" nz-tooltip="Delete" nz-icon nzType="delete" nzTheme="outline"
                             class="text-base text-danger cursor-pointer ml-5"
                            nz-popconfirm nzPopconfirmPlacement="bottom" (nzOnConfirm)="deleteRecord(record.id, i)"
                            nzPopconfirmTitle="Are you sure to remove this record?"></span>

                    </td>
                </tr>
            </tbody>
        </nz-table>
        <nz-divider class="mb-4" style="margin-top: 0 !important;"></nz-divider>
        <div class="flex flex-row flex-nowrap justify-end">
            <button nz-button type="button" class="bg-primary text-white py-1 px-6"
                [disabled]="multiRecordList.length === 0" (nzOnConfirm)="onSubmitAll()" nz-popconfirm
                nzPopconfirmTitle="Are you sure want to submit?" nzPopconfirmPlacement="bottom">
                {{multiRecordList.length > 1 ? 'Submit All ' : 'Submit'}}
            </button>
        </div>

    </div>
</form>