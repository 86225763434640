import { Directive, Input, HostListener } from '@angular/core';
import { AbstractControl } from '@angular/forms';

/**
 * Directive that allows selecting or deselecting all options in a multi-select form control.
 * When the special 'selectAllValue' is selected, all options are selected. If all options
 * are already selected, selecting 'selectAllValue' will deselect all.
 */
@Directive({
  selector: '[appSelectAll]'
})
export class SelectAllDirective {
  @Input('appSelectAll') options: { value: string; label: string }[] = [];
  @Input() control: AbstractControl | null = null;
  @Input() selectAllValue: string = 'SELECT_ALL';

  @HostListener('ngModelChange', ['$event'])
  onModelChange(selectedValues: string[]): void {
    if (!this.control) return;
    const allValues = this.options.map(option => option.value);
    if (selectedValues.includes(this.selectAllValue)) {
      const allSelected = allValues.every(value => selectedValues.includes(value));
      const newValues = allSelected ? [] : allValues;
      this.control.setValue(newValues);
    }
  }
}