<div class="w-12/12">
    <ng-container *ngIf="nodes && nodes.length > 0 && treeType === 'tree-select'">
        <form nz-form>
            <nz-form-item>
                <nz-form-label [nzNoColon]="false" [nzSm]="4" [nzXs]="24" class="w-full text-start"
                    nzFor="location">Locations</nz-form-label>
                <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please input your username!">
                    <nz-tree-select nzShowLine class="w-full" [nzDefaultExpandAll]="true" [nzAllowClear]="false"
                        [nzMultiple]="true" [nzStatus]="status" [nzMaxTagCount]="8"
                        [nzDropdownStyle]="{ maxHeight: '300px', overflow: 'auto'}" [nzHideUnMatched]="true"
                        nzShowExpand [ngModelOptions]="{standalone: true}" [(ngModel)]="value"
                        #tree [nzNodes]="nodes || []" (ngModelChange)="onChange(tree)" nzShowSearch nzCheckable
                        nzPlaceHolder="Please select location">
                    </nz-tree-select>
                </nz-form-control>
            </nz-form-item>
            <nz-alert nzShowIcon *ngIf="status === 'warning'" nzType="warning"
                nzMessage="Please select at least one location!"></nz-alert>
        </form>
    </ng-container>
    <ng-container *ngIf="treeType === 'tree-view'">
        <div class="min-h-[150px] max-h-[400px] w-full overflow-y-auto">
            <nz-tree-view [nzTreeControl]="treeControl" [nzDataSource]="dataSource" [trackBy]="trackBy">
                <nz-tree-node *nzTreeNodeDef="let node" nzTreeNodeIndentLine>
                    <nz-tree-node-option [nzDisabled]="node.disabled"
                        [nzSelected]="selectListSelection.isSelected(node)" (nzClick)="onNodeSelection(node)">
                        <i nz-icon nzType="hezky:Location" nzTheme="outline"></i>
                        {{ node.title }}
                    </nz-tree-node-option>
                </nz-tree-node>
                <nz-tree-node *nzTreeNodeDef="let node; when: hasChild" nzTreeNodeIndentLine>
                    <nz-tree-node-toggle>
                        <i nz-icon nzType="caret-down" nzTreeNodeToggleRotateIcon></i>
                    </nz-tree-node-toggle>
                    <nz-tree-node-option [nzDisabled]="node.disabled"
                        [nzSelected]="selectListSelection.isSelected(node)" (nzClick)="onNodeSelection(node)">
                        <span class="flex items-center gap-x-2">
                            <i *ngIf="node.level !== 0" nz-icon class="text-md" nzType="hezky:Location_Group"
                                nzTheme="outline"></i>
                            {{ node.title }}
                        </span>
                    </nz-tree-node-option>
                </nz-tree-node>
            </nz-tree-view>
        </div>
    </ng-container>
    
    <ng-container *ngIf="treeType === 'tree-view-search'">
        <div class="min-w-[275px] max-h-[75dvh] w-full overflow-y-auto no-scrollbar">
            <nz-input-group [nzSuffix]="suffixIcon" class="mb-2">
                <input type="text" nz-input placeholder="Search..." ngModel (ngModelChange)="searchValue$.next($event)" autocomplete="off"/>
            </nz-input-group>
            <ng-template #suffixIcon>
                <i nz-icon nzType="search"></i>
            </ng-template>
        
            <nz-tree-view [nzTreeControl]="treeControl" [nzDataSource]="dataSource" [trackBy]="trackBy">
                <nz-tree-node *nzTreeNodeDef="let node" nzTreeNodeIndentLine>
                    <nz-tree-node-option [nzDisabled]="node.disabled"
                        [nzSelected]="selectListSelection.isSelected(node)" (nzClick)="onNodeSelection(node)">
                        <i nz-icon nzType="hezky:Location" nzTheme="outline"></i>
                        <span [innerHTML]="node.title | nzHighlight: searchValue : 'i' : 'highlight'"></span>
                    </nz-tree-node-option>
                </nz-tree-node>
        
                <nz-tree-node *nzTreeNodeDef="let node; when: hasChild" nzTreeNodeIndentLine>
                    <nz-tree-node-toggle>
                        <i nz-icon nzType="caret-down" nzTreeNodeToggleRotateIcon></i>
                    </nz-tree-node-toggle>
                    <nz-tree-node-option [nzDisabled]="node.disabled"
                        [nzSelected]="selectListSelection.isSelected(node)" (nzClick)="onNodeSelection(node)">
                        <span class="flex items-center gap-x-2">
                            <i *ngIf="node.level !== 0" nz-icon class="text-md" nzType="hezky:Location_Group" nzTheme="outline"></i>
                            <span [innerHTML]="node.title | nzHighlight: searchValue : 'i' : 'highlight'"></span>
                        </span>
                    </nz-tree-node-option>
                </nz-tree-node>
            </nz-tree-view>
            <ng-container *ngIf="dataSource._data | async as locationDataSource">
                <nz-empty *ngIf="locationDataSource.length === 0" [nzNotFoundContent]="'No location found'"></nz-empty>
            </ng-container>
        </div>
    </ng-container>
</div>