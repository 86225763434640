import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sensorSort',
    pure: true,
})
export class SensorSortPipe implements PipeTransform {
    transform(value: any[] | null, key: string): any[] {
        if (!value?.length) return [];
        return value.map(asset => {
            const hotSpot = asset[key].find((sensor: { sensor_type: string; }) => sensor.sensor_type === "hot spot");
            const otherSensors = asset[key].filter((sensor: { sensor_type: string; }) => sensor.sensor_type !== "hot spot");
            return {
                ...asset,
                [key]: hotSpot ? [hotSpot, ...otherSensors] : asset[key]
            };
        });
    }
}