import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonService } from '../../../shared/services/common.service';
import { IncidentService } from '../../../modules/incidents/incident.service';

@Component({
  selector: 'app-incident-card',
  templateUrl: './incident-card.component.html',
  styleUrl: './incident-card.component.scss'
})
export class IncidentCardComponent {
  @Input() incidents: any[] | undefined = [];
  @Input() temp_pref: 'D' | 'F' = 'D';
  @Output() onCardClick = new EventEmitter();
  window: number = window.innerWidth;

  constructor(public commonService: CommonService, public incidentService: IncidentService) {
    this.temp_pref = this.commonService.temp_pref;
  }
  protected emitCardClick(incident: any) {
    this.onCardClick.emit(incident);
  }
}
