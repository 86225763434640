import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonService } from '../../shared/services/common.service';
import { NzTreeSelectComponent } from 'ng-zorro-antd/tree-select';
import { LocationState, selectTreeLocation } from './state/location-tree.state';
import { Store } from '@ngrx/store';
import { loadLocationsSuccess, loadTreeLocationsSuccess } from './state/location-tree.actions';
import { NzTreeNode } from '../../shared/types';
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { NzTreeFlatDataSource, NzTreeFlattener } from 'ng-zorro-antd/tree-view';
import { auditTime, BehaviorSubject, combineLatest, map } from 'rxjs';

interface FlatNode {
  expandable: boolean;
  title: string;
  key: string;
  level: number;
}
interface TreeNode {
  title: string;
  children?: TreeNode[];
}
class FilteredTreeResult {
  constructor(
    public treeData: TreeNode[] = [],
    public needsToExpanded: TreeNode[] = []
  ) {}
}

function filterTreeData(data: TreeNode[], value: string): FilteredTreeResult {
  const needsToExpanded = new Set<TreeNode>();
  const _filter = (node: TreeNode, result: TreeNode[]): TreeNode[] => {
    if (node.title.toLowerCase().includes(value.toLowerCase())) {
      result.push(node);
      return result;
    }
    if (Array.isArray(node.children)) {
      const nodes = node.children.reduce((a, b) => _filter(b, a), [] as TreeNode[]);
      if (nodes.length) {
        const parentNode = { ...node, children: nodes };
        needsToExpanded.add(parentNode);
        result.push(parentNode);
      }
    }
    return result;
  };
  const treeData = data.reduce((a, b) => _filter(b, a), [] as TreeNode[]);
  return new FilteredTreeResult(treeData, [...needsToExpanded]);
}

@Component({
  selector: 'app-location-tree',
  templateUrl: './location-tree.component.html',
  styles: [`
        ::ng-deep .highlight {
        color: #f50;
      }
  `],
})
export class LocationTreeComponent implements OnInit, OnChanges{
  @Input() value: any[] = [];
  @Input() nodes:any[] = [];
  @Input() treeData!: NzTreeNode[];
  @Input() treeType: 'tree-select' | 'tree-select-multiple' | 'tree-view' | 'tree-view-multiple' | 'tree-view-search' | null = null;
  @Output() nodeSelectEvent = new EventEmitter();

  flatNodeMap = new Map<FlatNode, NzTreeNode>();
  nestedNodeMap = new Map<NzTreeNode, FlatNode>();
  selectListSelection = new SelectionModel<FlatNode>(false);
  treeControl = new FlatTreeControl<FlatNode>(
    node => node.level,
    node => node.expandable
  );
  private transformer = (node: NzTreeNode, level: number): FlatNode => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.key === node.key
        ? existingNode
        : {
          expandable: !!node.children && node.children.length > 0,
          title: node.title,
          level,
          key: node.key
        };
    flatNode.title = node.title;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };
  treeFlattener = new NzTreeFlattener(
    this.transformer,
    node => node.level,
    node => node.expandable,
    node => node.children
  );
  selectedNodeData: FlatNode | null = null;
  dataSource = new NzTreeFlatDataSource(this.treeControl, this.treeFlattener);
  status: '' | 'error' | 'warning' = '';
  payload!: { userId: number, email: string, customerId: number, locationId?: number[] } | undefined;

  searchValue = '';
  originData$ = new BehaviorSubject(this.treeData);
  searchValue$ = new BehaviorSubject<string>('');
  expandedNodes: TreeNode[] = [];
  filteredData$ = combineLatest([
    this.originData$,
    this.searchValue$.pipe(
      auditTime(800),
      map(value => (this.searchValue = value))
    )
  ]).pipe(map(([data, value]) => (value ? filterTreeData(data as TreeNode[], value) : new FilteredTreeResult(data as TreeNode[]))));

  constructor(protected commonSvc: CommonService, protected store: Store<LocationState>) {
    this.value = this.commonSvc.assignedLocations;
    this.store.select(selectTreeLocation).subscribe(res => {
      if (res) { this.value = res.map(v => v) }
    });
    this.filteredData$.subscribe(result => {
      this.dataSource.setData(result.treeData as NzTreeNode[]);
      const hasSearchValue = !!this.searchValue;
      if (hasSearchValue) {
        if (this.expandedNodes.length === 0) {
          this.expandedNodes = this.treeControl.expansionModel.selected;
          this.treeControl.expansionModel.clear();
        }
        this.treeControl.expansionModel.select(...result.needsToExpanded as NzTreeNode[]);
        if (result.treeData.length) {
          this.treeControl.expand(this.treeControl.dataNodes[0]);
        }
      } else {
        if (this.expandedNodes.length) {
          this.treeControl.expansionModel.clear();
          this.treeControl.expansionModel.select(...this.expandedNodes as NzTreeNode[]);
          this.expandedNodes = [];
        }
      }
    })
  }
  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['treeData']?.currentValue) {
      this.dataSource.setData(changes['treeData'].currentValue);
      this.treeControl.expand(this.treeControl.dataNodes.filter(d => d.level === 0)[0]);
      this.originData$.next(changes['treeData'].currentValue);
      this.patchSelected();
    }
  }
  onNodeSelection(node: FlatNode): void {
    this.selectListSelection.toggle(node);
    if (this.selectListSelection.isSelected(node)) {
      this.selectedNodeData = node;
      if(this.selectedNodeData.expandable) {
       let selectedExpandedNode = this.treeData.find((d: NzTreeNode) => {
          return this.selectedNodeData && d.key === this.selectedNodeData.key ? d : []
        })
        this.value = this.treeData.filter(v => this.value.includes(v.key)).map(v => v.key);
        if(selectedExpandedNode?.children?.length) {
          this.value = selectedExpandedNode.children.map(v => Number(v.key));
        }
      }
      if(!this.selectedNodeData.expandable) {
      this.value = [Number(node.key)];
      }
      this.onModalSubmit();
    } else {
      this.selectedNodeData = null;
    }
  }
  protected onChange(value: NzTreeSelectComponent): void {
    this.value = this.getCheckedKeys(value.nzNodes).filter(key => typeof key === 'string');
  }
  protected onModalSubmit(): void {
    if (this.value.length === 0) {
      this.status = 'warning';
      return
    }
    this.store.dispatch(loadTreeLocationsSuccess({ tree_location: this.value.map(String) }));
    this.store.dispatch(loadLocationsSuccess({ location: this.value.map(Number) }));
    this.nodeSelectEvent.emit(true);
    localStorage.setItem('INIT', 'false');
  }
  protected getCheckedKeys(nodes: any[] | null | undefined): number[] {
    let keys: number[] = [];
    if (!Array.isArray(nodes)) {
      return keys;
    }
    nodes.forEach(node => {
      if (node && node.checked) {
        keys.push(node.key);
      }
      if (node && Array.isArray(node.children) && node.children.length > 0) {
        keys.push(...this.getCheckedKeys(node.children));
      }
    });
    keys = keys.filter(d => !isNaN(d));
    return keys;
  }
  patchSelected() {
    if (!this.treeControl.dataNodes) return;
    if (this.value.length === 1) {
      const selected = this.treeControl.dataNodes.find(node => this.value.includes(node.key));
      if (selected) this.selectListSelection.select(selected);
    }
  }
  hasChild = (_: number, node: FlatNode): boolean => node.expandable;
  hasNoContent = (_: number, node: FlatNode): boolean => node.title === '';
  trackBy = (_: number, node: FlatNode): string => `${node.key}-${node.title}`;
  expandAll(): void { this.treeControl.dataNodes?.forEach((node) => this.treeControl.expand(node)) }
}
