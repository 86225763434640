<div>
  <div class="mb-2 flex gap-2 justify-between">
    <nz-radio-group [(ngModel)]="currentFilter" (ngModelChange)="changeFilter($event)">
      <label nz-radio-button nzValue="all">All {{ notifications && notifications.length > 0 ? notifications.length : 0 }}</label>
      <label nz-radio-button nzValue="unread">Unread {{ unreadCount ? unreadCount : 0 }}</label>
    </nz-radio-group>
    <button nz-button nzType="default" type="button" [nzTooltipColor]="'#fff'" nz-tooltip nzTooltipTitle="Refresh" nzShape="circle"
    (click)="refreshHandler()">
    <i nz-icon nzType="reload"></i>
  </button>
  </div>
  
  <div class="desktop:w-96 desktop:h-80 tablet:w-96 tablet:h-80 phone:w-80 phone:h-80 mini:w-80 mini:h-80 overflow-y-auto" (scroll)="onScroll($event)">
    <nz-list [nzLoading]="initLoading" *ngIf="notifications && notifications.length > 0; else emptyTemplate">
      <ng-container *ngFor="let group of displayedNotifications; trackBy: commonSvc.trackByFn">
        <div class="date-section mb-4">
          <h4 class="text-gray-600 text-sm font-medium mb-2 sticky top-0 w-full bg-white z-50">{{ group.date }}</h4>
          <ng-container *ngIf="group && group.items.length > 0; else emptyTemplate">
            <ng-container *ngFor="let item of group.items; trackBy: commonSvc.trackByFn">
              <nz-list-item class="px-3"
                [ngClass]="item?.ismarked === false ? 'unread' : item?.ismarked === true ? 'readMarked' : 'unread'">
                <nz-list-item-meta [nzDescription]="item.notification_type" (click)="routeToComponent(item)">
                  <nz-list-item-meta-title>
                    <a>{{ item.notification }}</a>
                    <p *ngIf="group.date == 'Today' else other" class="text-grey-100 text-[11px]">
                      {{ commonSvc.getDateDifference(item?.created_at) }}
                    </p>
                    <ng-template #other>
                      <p class="text-grey-100 text-[11px]">
                        {{item?.created_at | date: 'hh:mm a'}}
                      </p>
                    </ng-template>
                  </nz-list-item-meta-title>
                </nz-list-item-meta>
                <ul nz-list-item-actions *ngIf="item?.ismarked !== true">
                  <nz-list-item-action><a (click)="markAsRead(item)">mark as read</a></nz-list-item-action>
                </ul>
              </nz-list-item>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
      <div *ngIf="initLoading" class="loading-more text-center py-4">
        <nz-spin></nz-spin>
      </div>
    </nz-list>
  
    <!-- Empty Template Based on Filter -->
    <ng-template #emptyTemplate>
      <div *ngIf="currentFilter === 'all'" class="h-full w-full place-content-center">
        <nz-empty [nzNotFoundContent]="'No notifications available'"></nz-empty>
      </div>
      <div *ngIf="currentFilter === 'unread'" class="h-full w-full place-content-center">
        <nz-empty [nzNotFoundContent]="'No unread notifications'"></nz-empty>
      </div>
    </ng-template>
  </div>
  
</div>