import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UsersService } from '../../../modules/admin/user-management/users.service';
import { countryCodes } from '../../../modules/admin/user-management/country_code';
import { CommonService } from '../../services/common.service';
export interface FormField {
  name: string;
  label: string;
  type: 'text' | 'email' | 'contact' | 'select' | 'multiselect' | 'checkbox';
  subLabel?: string;
  options?: Array<{ nzValue: any; nzLabel: string }>; // Update this type
  placeholder?: string;
  validators?: { type: string; value?: number, message: string }[];
  customAttributes?: { class: string;[key: string]: any };
  onChangeMethod?: (value: any) => void;
}

export interface FormConfig {
  formName: string;
  formType: 'create' | 'edit';
  fields: FormField[];
  data?: any;
  submitMethod: (data: any) => void;
}

@Component({
  selector: 'app-hezky-form',
  templateUrl: './hezky-form.component.html',
  styleUrl: './hezky-form.component.scss'
})
export class HezkyFormComponent implements OnInit {
  @Input() config!: FormConfig;
  formGroup!: FormGroup;
  multiRecordList: any[] = [];
  isMultirecord = false;
  isEditing = false;
  editIndex: number | null = null;
  countryCodes$: any[] = [];
  isGatewayForm: boolean = false;
  isSensorForm: boolean = false;

  constructor(
    protected commonSvc: CommonService,
    private fb: FormBuilder,
    private toaster: NzMessageService,
    public userService: UsersService) {
  }

  ngOnInit(): void {
    this.isGatewayForm = ['Edit Gateway', 'Add Gateway'].includes(this.config.formName);
    this.isSensorForm = ['Edit Sensor', 'Add Sensor'].includes(this.config.formName);
    this.initializeForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['config'].currentValue && !changes['config'].firstChange) {
      this.initializeForm();
    }
  }

  private initializeForm() {
    if (!this.config) return;
    this.createForm();
    if (this.config.formType === 'edit' && this.config.data) {
      setTimeout(() => {
        this.formGroup.patchValue(this.config.data);
      });
    }
  }

  createForm() {
    const formControls = this.config.fields.reduce<{ [key: string]: any }>((controls, field) => {
      if (field.type === 'contact') {
        controls['countryCode'] = [this.config.data?.countryCode || '+91', Validators.required];
        controls['contact'] = [this.config.data?.contact || '', [Validators.required, Validators.pattern(/^(\+\d{1,3}[- ]?)?\d{10}$/), Validators.minLength(10),]];
        this.countryCodes$ = countryCodes;
      } else if (field.type === 'checkbox' && this.isGatewayForm) {
        controls[field.name] = [{ value: this.config.data?.[field.name] || true, disabled: true }, Validators.required];
        controls['severity'] = [{ value: this.config.data?.severity || 'High' || true, disabled: true }, Validators.required];
      }
      else if (field.name === 'capabilities') {
        controls[field.name] = [{ value: this.config.data?.[field.name] || [], disabled: true }, Validators.required];
      }
      else {
        const initialValue = this.config.data?.[field.name] ?? '';
        controls[field.name] = [field.type === 'multiselect' ? [] : initialValue, this.getValidators(field.validators)];
      }
      return controls;
    }, {});
    this.formGroup = this.fb.group(formControls);
    this.onChange(formControls);
  }

  getValidators(validators: { type: string; message: string }[] | undefined) {
    if (!validators) return [];
    return validators.map((v) => {
      switch (v.type) {
        case 'required':
          return Validators.required;
        case 'minlength':
          return Validators.minLength(3);
        case 'maxlength':
          return Validators.maxLength(100);
        case 'email':
          return Validators.email;
        case 'pattern':
          return Validators.pattern(v.message);
        case 'min':
          return Validators.min(Number(v.message));
        case 'max':
          return Validators.max(Number(v.message));
        default:
          return null;
      }
    }).filter(validator => validator !== null);
  }

  hasValidatorType(field: any, type: string) {
    if (!field.validators) return false;
    return field.validators.some((validator: any) => validator.type === type);
  }

  onChange(formControls: { [key: string]: any; }) {
    this.formGroup = this.fb.group(formControls);
    this.config.fields.forEach(field => {
      if (field.onChangeMethod) {
        this.formGroup.get(field.name)?.valueChanges.subscribe(value => {
          field.onChangeMethod!(value);
        });
      }
    });
  }

  onAddAnother() {
    if (this.formGroup.valid) {
      this.isMultirecord = true;
      if (this.isEditing) {
        this.toaster.error('You are currently editing another record.');
        return;
      }
      if (this.isGatewayForm) {
        const serialNo = this.formGroup.controls['serial_no'].value;
        const isDuplicate = this.multiRecordList.some(record => record.serial_no === serialNo);
        if (isDuplicate) {
          this.toaster.error('Serial number is already present in the added list.');
          return;
        }
      }
      if (this.isSensorForm) {
        const deviceeui = this.formGroup.controls['deviceeui'].value;
        const external_sensor = this.formGroup.controls['external_sensor'].value;
        const isSensorDuplicate = this.multiRecordList.some(record => record.deviceeui === deviceeui);
        const isExtSensorDuplicate = this.multiRecordList.some(record => record.external_sensor === external_sensor);
        if (isSensorDuplicate) {
          this.toaster.error('Device EUI is already present in the added list.');
          return;
        }
        if (isExtSensorDuplicate) {
          this.toaster.error('External Sensor is already mapped in the added list.');
          return;
        }
      }
      this.toggleCapabilitiesField(true);
      const newRecord = { ...this.formGroup.value, id: this.multiRecordList.length + 1 };
      this.multiRecordList.push(newRecord);
      this.resetform();
      this.toggleCapabilitiesField(false);
    }
  }
  toggleCapabilitiesField(enable: boolean) {
    if (this.isSensorForm) {
      if (this.formGroup) {
        const capabilitiesControl = this.formGroup.get('capabilities');
        if (capabilitiesControl) {
          if (enable) {
            capabilitiesControl.enable();
          } else {
            capabilitiesControl.disable();
          }
        }
      }
    }
  }
  onEdit(record: any, index: number) {
    if (this.isEditing) {
      this.toaster.error('You are already editing another record. Do you want to replace it?', { nzDuration: 3000 });
      return;
    }
    this.isEditing = true;
    this.editIndex = index;
    this.formGroup.patchValue(record);
  }

  onUpdateRecord() {
    if (this.editIndex !== null && this.formGroup.valid) {
      const updatedRecord = { ...this.formGroup.value, id: this.multiRecordList[this.editIndex].id };
      if (JSON.stringify(this.multiRecordList[this.editIndex]) === JSON.stringify(updatedRecord)) {
        this.toaster.error('No changes detected.', { nzDuration: 3000 });
        return;
      }
      this.toggleCapabilitiesField(true);
      this.multiRecordList[this.editIndex] = updatedRecord;
      this.resetform();
      this.isEditing = false;
      this.toggleCapabilitiesField(false);
      this.editIndex = null;
    }
  }

  resetform() {
    if (this.isGatewayForm) {
      this.formGroup.controls['gateway_name'].reset();
      this.formGroup.controls['serial_no'].reset();
    } else {
      this.formGroup.reset()
    }
  }

  deleteRecord(id: number, index: number) {
    this.multiRecordList.splice(index, 1); // Removes one record at the given index
    if (this.multiRecordList.length === 0) {
      this.isMultirecord = false;
    }
  }

  onSubmitAll() {
    if (this.multiRecordList.length > 0) {
      this.config.submitMethod(this.multiRecordList);
      this.multiRecordList = [];
      this.isMultirecord = false;
    }
  }

  onSubmit() {
    this.toggleCapabilitiesField(true);
    if (this.commonSvc.validate(this.formGroup)) {
      this.config.submitMethod([this.formGroup.value]);
    }
    this.toggleCapabilitiesField(false);
  }

  getCustomAttributes(attributes: { [key: string]: any } = {}) {
    const customAttrs: { [key: string]: any } = {};
    if (attributes) {
      for (let key in attributes) {
        if (attributes.hasOwnProperty(key)) {
          customAttrs[key] = attributes[key];
        }
      }
    }
    return customAttrs;
  }

}