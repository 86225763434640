import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgZorroModule } from './ng-zorro.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { MaxTabsLimitExceededComponent } from './components/info-page/info-page.component';
import { HeaderComponent } from './components/header/header.component';
import { RouterModule } from '@angular/router';
import { NgApexchartsModule } from 'ng-apexcharts';
import { RelativeTimePipe } from './pipes/relative-time.pipe';
import { HezkyFormComponent } from './components/hezky-form/hezky-form.component';
import { GenericConfirmationDialogComponent } from './components/generic-confirmation-dialog/generic-confirmation-dialog.component';
import { IncidentCardComponent } from './components/incident-card/incident-card.component';
import { AssetCardsComponent } from './components/asset-cards/asset-cards.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SelectAllDirective } from './directives/select-all.directive';
import { NotificationComponent } from './components/notification/notification.component';
import { AllowOnlyNumberDirective } from './directives/number-only.directive';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { DeepLinkPageComponent } from './components/deep-link-page/deep-link-page.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { NzHighlightPipe } from 'ng-zorro-antd/core/highlight';
import { LocationTreeComponent } from '../modules/location-tree/location-tree.component';
import { SensorSortPipe } from './pipes/sensor-sort.pipe';
import { ReportNameModalComponent } from './components/modals/report-name-modal.component';


@NgModule({
  declarations:[
    PageNotFoundComponent,
    MaxTabsLimitExceededComponent,
    HeaderComponent,
    RelativeTimePipe,
    HezkyFormComponent,
    GenericConfirmationDialogComponent,
    IncidentCardComponent,
    AssetCardsComponent,
    SelectAllDirective,
    NotificationComponent,
    PrivacyPolicyComponent,
    AboutUsComponent,
    ContactUsComponent,
    AllowOnlyNumberDirective,
    DeepLinkPageComponent,
    TermsAndConditionsComponent,
    LocationTreeComponent,
    SensorSortPipe,
    ReportNameModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgZorroModule,
    FontAwesomeModule,
    NgApexchartsModule,
    ScrollingModule,
    NzHighlightPipe
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgZorroModule,
    FontAwesomeModule,
    NgApexchartsModule,
    ScrollingModule,
    HeaderComponent,
    RelativeTimePipe,
    HezkyFormComponent,
    GenericConfirmationDialogComponent,
    IncidentCardComponent,
    AssetCardsComponent,
    SelectAllDirective,
    NotificationComponent,
    AllowOnlyNumberDirective,
    PrivacyPolicyComponent,
    AboutUsComponent,
    ContactUsComponent,
    TermsAndConditionsComponent,
    DeepLinkPageComponent,
    SensorSortPipe,
    ReportNameModalComponent
  ],
  schemas : [NO_ERRORS_SCHEMA]
})
export class SharedModule { }
