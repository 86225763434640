import { Component } from '@angular/core';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { CommonService } from '../../services/common.service';
import { UiService } from '../../services/ui.service';
import { LocationState, selectTreeLocation } from '../../../modules/location-tree/state/location-tree.state';
import { Store } from '@ngrx/store';
import { NzTreeNode } from '../../types';
import { Router } from '@angular/router';
import { loadLocationTreeStructure } from '../../../modules/location-tree/state/location-tree.actions';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  drawer: boolean = false;
  noTfVisible: boolean = false;
  notifications: { notification_id: number, notification_type: string, notification: string, created_at: Date, ismarked: boolean }[] = [];
  notificationCount: number = 0;
  value: any[] = [];
  nodes: any[] = [];
  treeData!: NzTreeNode[];
  locationVisible: boolean = false;
  constructor(
    protected authenticationSvc: AuthenticationService,
    protected commonSvc: CommonService,
    public uiService: UiService,
    public router : Router,
    protected store: Store<LocationState>
  ) { }
  ngOnInit(): void {
    let payload = {
      userId: this.commonSvc.user_data.user_id,
      email: this.commonSvc.user_data.email,
      customerId: this.commonSvc?.user_data.customer?.customer_id,
      locationId: this.commonSvc.assignedLocations
    }
    this.commonSvc.fetchAccessLocationTree(payload).subscribe((res: any) => {
      if (res?.status === 200) {
        this.nodes = this.commonSvc.convertToNzTreeSelectFormat(res?.data);
        this.treeData = this.commonSvc.convertToNzTreeSelectFormat(res?.data) as NzTreeNode[];
        if(this.treeData) this.commonSvc.store.dispatch(loadLocationTreeStructure({ tree_location_structure: this.treeData }));
        this.store.select(selectTreeLocation).subscribe(res => {
          if (res) { this.value = res.map(v => v) }
        })
      }
    })
    this.notificationCount = this.commonSvc.user_data?.notificationCount;
  }
  noTfChange(value: any): void {
    value ? this.getAllNotification(): ''
  }
  getAllNotification() {
    const requestData = { "user_id": this.commonSvc.user_data.user_id, "timezone": this.commonSvc.time_zone }
    this.uiService.getAllNotifications(requestData).subscribe((response: any) => {
      if (response?.status === 200) {
        this.notifications = response.data;
        this.notificationCount = this.notifications?.filter((item: any) => item.ismarked === false || item.ismarked === null).length;
      }
    });
  }
  markAllAsRead() {
    const notificationIds: number[] = [];
    this.notifications?.forEach(notification => {
      if (notification.ismarked !== true) {
        notification.ismarked = true;
        notificationIds.push(notification.notification_id);
      }
    });
    if(notificationIds.length === 0){
      this.commonSvc.message.info('All notifications are already marked as read.');
      return;
    } 
    this.markNotificationAsRead(notificationIds)
  }

  markNotificationAsRead(items: any) {
    const requestData = {
      notification_id: Array.isArray(items) ? items : [items.notification_id],
      timezone: this.commonSvc.time_zone
    }
    this.uiService.markNotificationAsRead(requestData).subscribe((response: any) => {
      if (response?.status === 200) {
        this.getAllNotification();
      }
    })
  }
  refreshNotifications(){
    this.notifications = [];
    this.getAllNotification()
  }
  routetoNavigations(item: any) {
    switch (item.notification_type) {
      case 'Report Generated':
        this.router.navigate(['/reports/report-management']);
        break;
      case 'Form Action':
      case 'Incident Creation':
      case 'Incident Assigned':
        this.router.navigate([`/incidents/${item.routing_id}`]);
        break;
      default:
        break;
    }
    this.noTfVisible = false;
  }
  logoutHandler() {
    this.authenticationSvc.onLogout();
  }
}
